<template>
  <div class="settings-events">
    <!-- Types -->
    <div class="settings-block">
      <div class="settings-block__header">Appointment Types</div>
      <button class="btn settings-block__edit-btn" @click.prevent="updateTypes">
        Update
      </button>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <th>ID</th>
            <th>NAME</th>
          </thead>

          <tbody v-if="appTypes.length">
            <template v-for="(type, index) of appTypes">
              <tr :key="index">
                <td>{{ type.id }}</td>
                <td>{{ type.appointment_type_name }}</td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingsEvents",
  props: {
    appTypes: {
      type: Array,
      required: true,
    },
  },
  mounted() {},
  methods: {
    updateTypes() {
      this.$emit("onUpdateTypes");
    },
  },
};
</script>
