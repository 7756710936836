<template>
  <div class="settings">
    <Loader v-if="isLoading" :text="loadingMessage" />

    <div class="settings__tabs">
      <div
        class="settings__tab"
        :class="{ 'settings__tab-active': activeTab === 'account' }"
        @click="activeTab = 'account'"
      >
        Account
      </div>
      <div
        class="settings__tab"
        :class="{ 'settings__tab-active': activeTab === 'events' }"
        @click="activeTab = 'events'"
        v-if="sh_admin"
      >
        Trigger Events
      </div>
      <div
        class="settings__tab"
        :class="{ 'settings__tab-active': activeTab === 'payments' }"
        @click="activeTab = 'payments'"
        v-if="sh_admin"
      >
        Payments
      </div>
    </div>

    <SettingsAccount v-if="activeTab === 'account'" />
    <SettingsEvents
      v-if="activeTab === 'events' && appTypes.length"
      @onUpdateTypes="updateTypes"
      :appTypes="appTypes"
    />
    <SettingsPayments
      v-if="activeTab === 'payments'"
      :allowables="allowables"
      @onUpdateInsurance="updateInsurance"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Loader from '@/components/loader/loader.vue';
import SettingsAccount from './settings-account/settings-account.vue';
import SettingsEvents from './settings-events/settings-events.vue';
import SettingsPayments from './settings-payments/settings-payments.vue';

const { mapActions: customerActions, mapGetters: customerGetters } =
  createNamespacedHelpers('customer');

export default {
  name: 'Settings',
  props: {},
  components: {
    Loader,
    SettingsAccount,
    SettingsEvents,
    SettingsPayments,
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      user: 'USER',
    }),
    sh_admin() {
      return this.customer.customer_id === 0;
    },
  },
  data() {
    return {
      activeTab: 'account',
      appTypes: [],
      allowables: [],
      isLoading: false,
      loadingMessage: '',
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    ...customerActions(['fetchCustomerMappings']),
    async getInfo() {
      this.isLoading = true;
      this.loadingMessage = 'Getting appointment types...';
      await this.getAppTypes();
      await this.fetchCustomerMappings();

      this.isLoading = false;
      this.loadingMessage = '';
    },
    getAppTypes() {
      const URL = '/proxy/get-appointment-types';
      return window.axios
        .post(URL, {
          customer_id: this.customer.customer_id,
        })
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.appTypes = response.data.types;
          } else throw new Error(`Appointment types response invalid`);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    async updateTypes() {
      this.isLoading = true;
      this.loadingMessage = 'Getting appointment types...';

      await this.getUpdatedTypes();
      await this.getAppTypes();

      this.isLoading = false;
      this.loadingMessage = '';
    },
    getUpdatedTypes() {
      const URL = '/proxy/get-customer-app-types';
      return window.axios
        .post(URL, {
          customerID: this.customer.customer_id,
        })
        .then((response) => {
          if (!response || !response.data.success) {
            throw new Error(`Appointment types response invalid`);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    updateInsurance(data) {
      this.allowables = data;
    },
  },
};
</script>

<style lang="scss">
@import '@/sass/variables.scss';
@import './settings.scss';
</style>
