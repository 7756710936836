<template>
  <div class="settings-payments">
    <Loader v-if="isLoading" :text="loadingMessage" />
    <!-- Insurance Mapping -->
    <div class="settings-block">
      <div class="settings-block__header">Insurance Mapping</div>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <th>COMPANY NAME</th>
            <th>ALLIASES</th>
            <th></th>
          </thead>
          <tbody>
            <template v-for="(map, index) of tempInsurance">
              <tr
                class="settings-block__item"
                :key="index"
                :class="{
                  'settings-block__item-selected': insuranceItemView === index,
                  'filled-line': index % 2 && insuranceItemView !== index,
                }"
              >
                <td>{{ map.company_name }}</td>
                <td class="settings-payments__aliases">
                  <template v-for="(alias, ind) of map.greenway_aliases">
                    <div
                      v-if="alias"
                      class="settings-payments__aliases__item greenway-aliases"
                      :key="ind"
                    >
                      <v-text-field
                        v-if="
                          editingGreenwayAlias.companyId &&
                          editingGreenwayAlias.companyId === map.id &&
                          editingGreenwayAlias.greenwayIndex !== null &&
                          editingGreenwayAlias.greenwayIndex === ind
                        "
                        label="Alias"
                        v-model="editingGreenwayAlias.alias"
                        solo
                      ></v-text-field>
                      <span v-else>{{ alias }}</span>

                      <div
                        v-if="
                          editingGreenwayAlias.companyId &&
                          editingGreenwayAlias.companyId === map.id &&
                          editingGreenwayAlias.greenwayIndex !== null &&
                          editingGreenwayAlias.greenwayIndex === ind
                        "
                        class="greenway-buttons"
                      >
                        <v-btn icon @click.stop="editAlias(map.id, index, ind)">
                          <v-icon> mdi-content-save </v-icon>
                        </v-btn>
                        <v-btn icon @click.stop="toggleGreenwayEdit">
                          <v-icon> mdi-minus-circle </v-icon>
                        </v-btn>
                      </div>
                      <div v-else class="greenway-buttons">
                        <v-btn
                          icon
                          @click.stop="toggleGreenwayEdit(map.id, ind, alias)"
                        >
                          <v-icon> mdi-pen </v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          @click.stop="removeAlias(map.id, index, ind)"
                        >
                          <v-icon> mdi-delete </v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </template>
                  <div
                    class="settings-payments__aliases__item-add"
                    v-if="insuranceItemView == index"
                  >
                    <input
                      class="settings-item__value editable"
                      type="text"
                      name="newInsurance"
                      v-model="tempGreenwayAlias"
                      v-on:keyup="updateInsuranceData($event, index)"
                    />
                    <div
                      class="settings-payments__aliases__add-btn"
                      @click="addAlias(index)"
                    ></div>
                  </div>
                </td>
                <td>
                  <button
                    class="btn settings-block__edit"
                    type="button"
                    v-if="insuranceItemView !== index"
                    v-on:click.prevent="editInsuranceSettings($event, index)"
                  >
                    Edit
                  </button>
                  <button
                    class="
                      btn
                      settings-block__edit settings-block__edit-success
                    "
                    v-else
                    @click.prevent="onSubmitInsurance(map.id, index)"
                  >
                    Save
                  </button>
                  <br />
                  <button
                    class="btn settings-block__edit"
                    type="button"
                    v-if="insuranceItemView === index"
                    v-on:click.prevent="editInsuranceSettings($event, index)"
                  >
                    Cancel
                  </button>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <!-- Insurance Allowables -->
    <div class="settings-block">
      <div class="settings-block__header">Insurance Allowables</div>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <th>COMPANY ID</th>
            <th>INSURANCE</th>
            <th>CPT</th>
            <th>ALLOWABLE</th>
            <th></th>
          </thead>
          <tbody>
            <template v-for="(item, index) of tempAllowableList">
              <tr
                :key="index"
                class="settings-block__item"
                :class="{
                  'settings-block__item-selected': allowableItemView === index,
                }"
              >
                <td>{{ item.id }}</td>
                <td>{{ item.insurance }}</td>
                <td>{{ item.cpt_code }}</td>
                <td>${{ item.amount }}</td>
                <td>
                  <button
                    class="btn settings-block__edit"
                    type="button"
                    v-if="allowableItemView !== index"
                    v-on:click.prevent="editAllowable($event, index)"
                  >
                    Edit
                  </button>
                  <button
                    class="
                      btn
                      settings-block__edit settings-block__edit-success
                    "
                    v-else
                    @click.prevent="onSubmitAllowable(item.id, index)"
                  >
                    Save
                  </button>
                  <br />
                  <button
                    class="btn settings-block__edit"
                    type="button"
                    v-if="allowableItemView === index"
                    v-on:click.prevent="editAllowable($event, index)"
                  >
                    Cancel
                  </button>
                </td>
              </tr>
              <tr
                v-if="allowableItemView === index"
                :key="`_${index}`"
                class="settings-block__item"
                :class="{
                  'settings-block__item-selected': allowableItemView === index,
                }"
              >
                <td colspan="5">
                  <div class="settings-block__item-details">
                    <div class="settings-item settings-item_c6of12">
                      <v-text-field
                        label="CPT:"
                        name="number"
                        v-on:keyup="updateAllowableData($event, index)"
                        v-model="item.cpt_code"
                      >
                      </v-text-field>
                    </div>
                    <div class="settings-item settings-item_c6of12">
                      <v-text-field
                        label="ALLOWABLE($):"
                        name="amount"
                        v-on:keyup="updateAllowableData($event, index)"
                        v-model="item.amount"
                      >
                      </v-text-field>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <!-- Defaults -->
    <div class="settings-block">
      <div class="settings-block__header">Defaults</div>
      <button
        class="btn settings-block__edit-btn settings-block__edit-btn-success"
        v-if="defaultsSettingsEditable"
        v-on:click.prevent="saveDefaultsSettings"
      >
        Save
      </button>
      <button
        class="btn settings-block__edit-btn settings-block__edit-btn-cancel"
        v-if="defaultsSettingsEditable"
        v-on:click.prevent="editDefaultsSettings"
      >
        Cancel
      </button>
      <button
        class="btn settings-block__edit-btn"
        v-if="!defaultsSettingsEditable"
        v-on:click.prevent="editDefaultsSettings"
      >
        Edit
      </button>
      <div class="settings-block__defaults">
        <ValidationObserver>
          <form>
            <table>
              <tbody>
                <tr>
                  <td>
                    <v-row>
                      <v-col cols="12" md="6">
                        <ValidationProvider
                          name="paymentDenominator"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            label="Prepay Plan Max Terms"
                            name="paymentDenominator"
                            v-bind:disabled="!defaultsSettingsEditable"
                            v-on:keyup="updateDefaultsData"
                            v-model="tempDefaults.paymentDenominator"
                          >
                          </v-text-field>
                          <div class="invalid-data">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12" md="6">
                        <ValidationProvider
                          name="LicenseVersion"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            label="License Version"
                            name="LicenseVersion"
                            v-bind:disabled="!defaultsSettingsEditable"
                            v-on:keyup="updateDefaultsData"
                            v-model="tempDefaults.LicenseVersion"
                          >
                          </v-text-field>
                          <div class="invalid-data">{{ errors[0] }}</div>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <div class="form-group">
                      <ValidationProvider
                        name="estimationText"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-textarea
                          label="Cost Estimate Text"
                          name="estimationText"
                          v-bind:disabled="!defaultsSettingsEditable"
                          v-model="tempDefaults.estimationText"
                          v-on:keyup="updateDefaultsData"
                        ></v-textarea>
                        <div class="invalid-data">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <div class="form-group">
                      <ValidationProvider
                        name="policyText"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-textarea
                          label="Policy Text"
                          name="policyText"
                          v-bind:disabled="!defaultsSettingsEditable"
                          v-model="tempDefaults.policyText"
                          v-on:keyup="updateDefaultsData"
                        ></v-textarea>
                        <div class="invalid-data">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <div class="form-group">
                      <ValidationProvider
                        name="CustomerName"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-textarea
                          label="License Text"
                          name="LicenseText"
                          v-bind:disabled="!defaultsSettingsEditable"
                          v-model="tempDefaults.LicenseText"
                          v-on:keyup="updateDefaultsData"
                        ></v-textarea>
                        <div class="invalid-data">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Loader from "@/components/loader/loader.vue";

const { mapActions: customerActions, mapGetters: customerGetters } =
  createNamespacedHelpers("customer");

export default {
  name: "SettingsPayments",
  props: {
    allowables: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tempDefaults: {},
      updatedDataDefaults: {},
      defaultsSettingsEditable: false,
      insuranceItemView: null,
      allowableItemView: null,
      tempInsurance: [],
      tempAllowableList: [],
      tempGreenwayAlias: "",
      isReadyToUpdateInsurance: false,
      tempAllowableData: null,
      editingGreenwayAlias: {
        companyId: null,
        greenwayIndex: null,
        alias: "",
      },
      isLoading: false,
      loadingMessage: "",
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    Loader,
  },
  watch: {
    allowables(value) {
      this.tempAllowableList = JSON.parse(JSON.stringify(value));
    },
    customerMappings(value) {
      this.tempInsurance = JSON.parse(JSON.stringify(value));
    },
  },
  computed: {
    ...customerGetters({
      customer: "CUSTOMER",
      customerMappings: "CUSTOMER_MAPPINGS",
    }),
  },
  mounted() {
    this.tempDefaults = Object.assign({}, this.customer);
    this.tempInsurance = JSON.parse(JSON.stringify(this.customerMappings));
    this.tempAllowableList = JSON.parse(JSON.stringify(this.allowables));
  },
  methods: {
    ...customerActions(["deleteGreenwayAlias", "updateCustomerMappings"]),
    editInsuranceSettings(e, index) {
      if (e && e.target && e.target.desabled) {
        return;
      }
      this.tempGreenwayAlias = "";
      this.tempInsurance = JSON.parse(JSON.stringify(this.customerMappings));
      this.insuranceItemView = index !== this.insuranceItemView ? index : null;
    },
    editDefaultsSettings(e) {
      if (!e || e.target.desabled) {
        return;
      }
      this.updatedDataDefaults = {};
      this.isReadyToUpdateInsurance = false;
      this.tempDefaults = Object.assign({}, this.customer);
      this.defaultsSettingsEditable = !this.defaultsSettingsEditable;
    },
    editAllowable(e, index) {
      if (!e || e.target.desabled) {
        return;
      }
      this.tempAllowableList = JSON.parse(JSON.stringify(this.allowables));
      this.tempAllowableData = this.tempAllowableList[index];
      this.allowableItemView = index !== this.allowableItemView ? index : null;
    },
    saveDefaultsSettings() {
      this.defaultsSettingsEditable = !this.defaultsSettingsEditable;
      this.onSubmitDefaults();
    },
    updateInsuranceData(e) {
      this.tempGreenwayAlias = e.target.value;
    },
    updateDefaultsData(e) {
      let el = e.target;
      if (el.value != this.customer[el.name]) {
        this.updatedDataDefaults = {
          ...this.updatedDataDefaults,
          [el.name]: el.value,
        };
      } else {
        if (this.updatedDataDefaults[el.name]) {
          delete this.updatedDataDefaults[el.name];
        }
      }
    },
    updateAllowableData(e) {
      let el = e.target;
      this.tempAllowableData[el.name] = el.value;
    },
    addAlias(index) {
      if (this.tempGreenwayAlias) {
        this.tempInsurance[index].greenway_aliases.push(this.tempGreenwayAlias);
        this.tempGreenwayAlias = "";
        this.isReadyToUpdateInsurance = true;
      }
    },
    stringifyAliases(data) {
      const stringifiedData = {};
      data.forEach((element, index) => {
        stringifiedData[index] = element;
      });
      return JSON.stringify(stringifiedData);
    },
    onSubmitDefaults() {
      if (Object.keys(this.updatedDataDefaults).length) {
        this.isLoading = true;
        this.loadingMessage = "Updating data...";

        window.axios
          .post("/proxy/change-customer-info", {
            updated_type: "customer",
            updated_data: this.updatedDataDefaults,
            destination_id: this.customer.customer_id,
          })
          .then((response) => {
            if (response.data && response.data.success === true) {
              this.$store.commit("customer/UPD_CUSTOMER", this.tempDefaults);
              this.editDefaultsSettings();
            }
            this.isLoading = false;
            this.loadingMessage = "";
          });
      } else {
        this.editDefaultsSettings();
      }
    },
    onSubmitInsurance(id, index) {
      if (!this.isReadyToUpdateInsurance) return;
      this.isLoading = true;
      this.loadingMessage = "Updating data...";
      const URL = "/proxy/set-customer-mappings";
      return window.axios
        .post(URL, {
          id: id,
          customer_id: this.customer.customer_id,
          updatedFields: {
            greenway_aliases: this.tempInsurance[index].greenway_aliases,
          },
        })
        .then((response) => {
          if (response && response.data.success) {
            this.$store.commit("customer/SET_CUSTOMER_MAPPINGS", this.tempInsurance);
            this.insuranceItemView = null;
          } else throw new Error(`Submit Insurance response invalid`);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.isLoading = false;
          this.loadingMessage = "";
        });
    },
    onSubmitAllowable(id, index) {
      this.isLoading = true;
      this.loadingMessage = "Updating data...";
      const URL = "/proxy/set-customer-mappings";
      return window.axios
        .post(URL, {
          id: id,
          customer_id: this.customer.customer_id,
          updatedFields: {
            allowables: {
              [this.tempAllowableData.cpt_code]: {
                cpt_code: this.tempAllowableData.cpt_code,
                amount: this.tempAllowableData.amount,
              },
            },
          },
        })
        .then((response) => {
          if (response && response.data.success) {
            this.tempAllowableList[index].amount =
              this.tempAllowableData.amount;
            this.tempAllowableList[index].cpt_code =
              this.tempAllowableData.cpt_code;
            this.$emit("onUpdateInsurance", this.tempAllowableList);
            this.allowableItemView = null;
          } else throw new Error(`Submit Insurance Allowable response invalid`);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.isLoading = false;
          this.loadingMessage = "";
        });
    },
    toggleGreenwayEdit(companyId, greenwayIndex, alias) {
      if (this.editingGreenwayAlias.alias && alias) return;

      this.editingGreenwayAlias.companyId = companyId;
      this.editingGreenwayAlias.greenwayIndex = greenwayIndex;
      this.editingGreenwayAlias.alias = alias;
    },
    async editAlias(insuranceMappingId, mapIndex, aliasIndex) {
      this.isLoading = true;
      this.loadingMessage = "Updating alias";
      await this.updateCustomerMappings({
        insuranceMappingId,
        mapIndex,
        alias: this.editingGreenwayAlias.alias,
        aliasIndex,
      });
      this.toggleGreenwayEdit();

      this.$store.commit("shared/SET_CONTENT_PRELOADER", {
        show: false,
        text: "",
      });

      this.isLoading = false;
      this.loadingMessage = "";
    },
    async removeAlias(insuranceMappingId, mapIndex, aliasIndex) {
      this.isLoading = true;
      this.loadingMessage = "Deleting alias";
      await this.deleteGreenwayAlias({
        insuranceMappingId,
        mapIndex,
        aliasIndex,
      });

      this.isLoading = false;
      this.loadingMessage = "";
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/variables.scss";

.greenway-aliases {
  display: flex;
  align-items: center;

  .greenway-buttons {
    display: flex;
  }
}
</style>
